import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import { createI18n } from "vue-i18n";

const i18n = createI18n({
  legacy: false,
  locale: "ES", // set locale
  fallbackLocale: "ES", // s
});

createApp(App).use(router).use(i18n).mount("#app");

<template>
  <div v-bind="$attrs" id="tam-content">
    <slot></slot>
  </div>
</template>
<script setup lang="ts"></script>
<style lang="scss">
#tam-content {
  padding: 0px 15px;

  #content {
    margin: 0 auto;
    max-width: 1024px;
    background-color: rgba(white, 0.9);
    padding: 60px 15px;
  }

  #icon {
    border-radius: 50%;
    height: 120px;
    width: 120px;
    margin: 0 auto;
    background-color: $c-1;
    position: relative;
    top: 60px;

    > * {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 80%;
      width: 80%;
    }
  }
}
#tam-cont:after {
  content: "";
  display: table;
  clear: both;
}

.tam-title {
  font-size: 30px;
  font-weight: 600;
  width: 80%;
  padding: 20px;
  margin: 0 auto;
  text-align: center;
  color: $c-1;
}
.tam-subtitle {
  font-size: 25px;
  font-weight: 600;
  width: 70%;
  padding: 15px;
  margin: 0 auto;
  text-align: center;
  color: $c-2;
}

.tam-text {
  font-size: 17px;
  padding: 5px;
  color: $c-5;
  text-align: center;
}

.tam-button {
  font-size: 17px;
  font-weight: 500;
  color: white;
  background-color: $c-1;
  padding: 10px;
  border-radius: 25px;
  border: none;
  display: block;
  margin: 0 auto;
  min-width: 200px;
}

.tam-subbutton {
  font-size: 17px;
  color: $c-1;
  padding: 10px;
  border: 2px solid $c-1;
  border-radius: 25px;
}

.tam-input {
  font-size: 17px;
  color: black;
  padding: 10px;
  border: 2px solid black;
  border-radius: 25px;
  margin: 0 auto;
  display: block;
  text-align: center;
}

.tam-input:active {
  color: $c-1;
  border: 2px solid $c-1;
}

.tam-section {
  padding-top: 10px;
}
</style>
